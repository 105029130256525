const language = {
    'pt': 
        {
            abstract:           'Resumo', 
            accept_terms:       'Aceito os termos e condições.',
            access_app:         'Acesso à área de cliente',
            access_data:        'Dados de acesso',
            accesses:           'Acessos',
            accesses_info:      'Informação da responsabilidade das Infraestruturas de Portugal. <br><br>Para mais informações, consulte o site: <a href="https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real" target="_blank">https://servicos.infraestruturasdeportugal.pt</a>',
            adress:             'Morada', 
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions: 'Concordo com os ', 
            article:            'Artigo',
            articles_submission:'Submissão de artigos',
            author_regist:      'Registo de autor', 
            authors:            'Autores',  
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:      'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            at:                 'às',
            back:               'Voltar',
            banner_submission:  'Submeta os seus artigos',
            banner_submission_2:'Conheça os próximos períodos de submissão e todas as informações necessárias.',
            banner_article_submission_text:  'Para submeter os seus artigos faça o seu login/registo e junte-se a nós!',
            bibliography:       'Bibliografia',
            biography:          'Biografia',
            cancel:             'Cancelar',
            cfp_status_closed:  'fechado',
            cfp_status_ending:  'a terminar',
            cfp_status_open:    'aberto',
            cfp_status_soon:    'brevemente',
            change_password_expiration_message:       'O código para alterar a sua palavra-passe expirou, faça um novo pedido por favor.',
            change_password_sucess_message:           'Palavra-passe atualizada com sucesso',
            client_regist:      'Resgisto de Novo Cliente',
            close:              'Fechar',
            closed:             'Fechado',
            code:               'Código',
            complaint_book:     'Livro de reclamações',
            confirmation_password_error_message:      'Palavra-passe de confirmação diferente da palavra-passe inserida',
            contest:            'Concurso',
            contacts:           'Contactos',  
            contact:            'Contacto',  
            contact_email:      'Email de contacto',   
            contact_form:       'Formulário de contacto',  
            contact_recovery:   'Formulário de recuperação',  
            content:            'Conteúdo',
            content_edit:       'Editar conteúdo',
            content_new:        'Novo conteúdo',
            content_list:       'Lista de conteúdos',
            content_preview:    'Isto é uma Pré-visualização!', 
            cookies_message:    'Este site utiliza cookies. Ao navegar no site consente a sua utilização.',
            cookie_button_text : 'Aceitar',
            country:            'País',
            current_state:      'Estado Atual',
            dates:              'Datas',
            birthday:         'Data de nascimento',
            date_creation:      'Criação',
            date_publication:   'Publicação',
            date_last_update:   'Última actualização',
            delete:             'Eliminar',
            delete_title:       'Eliminar registo',
            delete_text:        'Tem a certeza que pretende continuar?',
            delete_confirm:     'Sim',
            delete_cancel:      'Não',
            delete_error:       'Problema ao apagar registo!',
            delete_success:     'Registo apagado com sucesso!',
            description:        'Descrição',
            developed:          'Desenvolvido por',
            difficulty:         'Dificuldade',
            dificil:            'Difícil',
            discover:           'Descobrir',
            download_app:       'Descarregar app',
            payment_methods:    'Métodos de pagamento',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Designação da ideia/projeto',
            edit:               'Editar',
            edit_title:         'Editar Registo',
            edit_text:          'Pretende continuar?',
            edit_confirm:       'Sim',
            edit_cancel:        'Não',
            edit_error:         'Problema ao actualizar registo!',
            edit_success:       'Registo actualizado!',
            edition:            'Edição',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Recebemos a tua candidatura, vamos analisá-la e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Introduza o email',
            end:                'Fim',
            enter_country_residence:      'Introduza o país de residência',
            entrepreneur_information:     'Informação de Apoio ao Empreendedor',
            facil:              'Fácil',
            faqs:               'Perguntas frequentes', 
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'Ficheiro(s)',
            filter_placeholder: 'Procurar',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   '',
            footer_address_2:   '',
            footer_address_3:   '',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            form_autor_register: 'Formulário de registo de autor',
            full_edition:       'Edição completa',
            galleries:          'Galerias',
            go_platform:        'Ir para a plataforma',
            hello:              'Olá',
            id:                 'ID',
            image:              'Imagem',
            image_choose:       'Escolher imagem',
            info_banner_text:   'Todas as informações necessárias para começar',
            information:        'Informações',
            insert_title:       'Novo Registo',
            insert_text:        'Pretende continuar?',
            insert_confirm:     'Sim',
            insert_cancel:      'Não',
            insert_error:       'Problema ao inserir registo!',
            insert_success:     'Registo inserido!',
            labels:             'Legendas',
            latest_update:      'Última actualização',
            learn_more:         'Saber mais',
            legal_information:  'Informação legal',
            length:             'Comprimento',
            livecam:            'Câmaras ao vivo',
            livecam_2:            'Câmara',
            location:           'Localização',
            login:              'Iniciar sessão',
            logout:             'Sair',
            maximum_numbers_of_caracters:   'Número máximo de caracteres',
            marked_tracks:          'Pistas balizadas',
            mechanical_means:       'Meios mecânicos',
            mechanical_means_info:  'Desnível esquiável de 137 metros | 1988m - 1851m',
            message:            'Mensagem',
            message_placeholder:    'Introduza a mensagem',
            meteorology:        'Meteorologia',
            more:               'Mais informação:',
            more_information:               'Mais informações.',
            muito_dificil:      'Muito difícil',
            muito_facil:        'Muito fácil',
            multimedia:         'Multimédia',
            name:               'Nome',
            name_placeholder:   'Introduza o nome',
            natural_tracks:         'Pistas naturais',
            natural_tracks_info:    'Pistas abertas sempre que exista neve suficiente',
            not_applicable:       'Não aplicável',
            not_found:          'Página inicial',
            not_found1:         'Sem resultados',
            not_found2:         'A página que procura pode ter sido removida, ter o seu nome alterado ou estar temporariamente indisponível. ',
            not_found3:         'Voltar à página inicial. ',
            observations:       'Observações',
            opinion_banner_button:   'Deixar opinião',
            opinion_banner_title:    'Deixe-nos a sua opinião',
            opinion_banner_text:     'A sua opinião também conta',
            organization:       'Organização',
            open:               'Aberto',
            open_file:          'Ver ficheiro',
            opinion_form:       'Formulário de opinião',
            page_config:        'Configuração da Página',
            page_parent:        'Parent',
            pages:              'Páginas',
            partnerships:       'Parcerias',
            password:           'Palavra-passe',
            password_new:       'Nova palavra-passe',
            password_conf:      'Confirmação da palavra-passe',
            personal_data:      'Dados pessoais',
            platforms:          'Plataformas',
            platforms_banner_text:  'Conheça as nossas plataformas que temos para si',
            principal:          'Principal',
            privacy_policy:     'Política de privacidade',
            profile_image:      'Imagem de perfil',
            project:            'Projeto',
            project_banner_text:    'Conhece todo o nosso projeto',
            projeto_cofinanciado: 'Projeto cofinanciado UE: CENTRO-04-38D7-FEDER-001180',
            programs:           'Programas',
            px_hour:            'px/hora',
            read_article:       'Ler artigo',
            read_terms_conditions: 'Leia os nossos termos e condições antes de submeter o formulário.', 
            regist:             'Registo',
            regist_form_sucess_message: 'Registo efetuado com sucesso. Consulte os seus dados de acesso no email enviado.',
            register:           'Registe-se',
            registry_defined:   'Definida no registo',
            regulations:        'Condições gerais de aulas',
            remove:             'Remover',
            reserved_rights:    'Todos os direitos reservados',
            resort_serra_da_estrela:    'Estância de Ski Serra da Estrela',
            return_policy:      'Política de Devolução e Reembolso',
            save:               'Salvar', 
            search:             'Pesquisar', 
            search_by_article_name:             'Pesquisa por nome de artigo', 
            search_by_author_name:              'Pesquisa por nome de autor', 
            search_by_article_keyword:          'Pesquisa por keywords', 
            search_error_message:               'Preencha um dos campos de pesquisa.',
            search_error_noresults_message:     'Não foi encontrado nenhum resultado.',
            seats:              'Lugares',
            see_it_all:         'Ver tudo',
            send:               'Enviar',   
            sent_successfully:  'Enviado com sucesso',
            seo_config:         'Configuração SEO',
            seo_description:    'Descrição SEO',
            seo_description_placeholder: 'Introduza a descrição SEO',
            seo_keywords:       'Keywords SEO',
            seo_keywords_small: '(separar por vírgulas)',
            seo_keywords_placeholder: 'Introduza as palavras-chave SEO',
            seo_title:          'Título SEO',
            seo_title_placeholder: 'Introduza o título SEO',
            site_map:           'Mapa do site',
            ski_school:         'Escola de Ski', 
            slide_title:        'Juntos partilhamos conhecimento',
            snowpark:           'Snowpark',
            subject:            'Assunto',
            snowpark_info:      'No snowpark aconselha-se o uso de capacete',
            social_media:       'Redes Sociais',
            start:              'Início',
            state:              'Estado',
            submission:                   'Submissão',
            submission_rules:             'Normas de submissão',
            submission_rules_pdf:         'Descarregar instruções',
            submission_rules_pdf_view:    'Ver normas de submissão',
            submit:             'Submeter',
            submit_article:     'Submeter artigo',
            surname:            'Apelido',
            table_rows_per_page:'Registos por página:',
            table_separator:    'de',
            table_all:          'Todos',
            table_title:        'Título',
            table_parent:       'Parent',
            table_date:         'Data',
            table_state:        'Estado',
            table_actions:      'Acções',
            technical_team:     'Corpo técnico',
            temperature:        'Temperatura',
            terms_conditions:   'Termos e condições',          
            terms_conditions_link:   'termos e condições.',
            terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeter não será usada ou distribuída por outra razão que não o processamento do seu pedido.',
            testimonial_form:             'Formulário de testemunho',
            testimonials:                 'Testemunhos',
            testimonials_button_message:  'Deixar testemunho',
            text:               'Texto',
            title:              'Título',
            title_placeholder:  'Introduza o título',
            tooltip_bar:                  'Bar',
            tooltip_forfait_sales:        'Posto de Venda Passe Ski/Snowboard',
            tooltip_material_rental:      'Aluguer de material',
            tooltip_parking_lot:          'Parque de estacionamento',
            tooltip_restaurant:           'Restaurante',
            tooltip_shopping_center:      'Centro comercial',
            tooltip_wc:                   'WC',
            track_map:                    'Mapa das pistas',
            tracks:                       'Pistas',
            tracks_with_snow_cannon:      'Pistas com canhão de neve',
            turistrela_group:             'Grupo Turistrela',
            turistrela_eat:               'Onde comer',
            turistrela_sleep:             'Onde dormir',
            unevenness:                   'Desnível',
            user:                         'Utilizador',
            username_email:               'Email para login', 
            username_error_message:       'O nome de utilizador já existe.',
            velocity:                     'Velocidade',
            web_page:                     'Página Web',
            agendar_aula:                 'Agendar Aula',
            my_account:                   'Os meus dados',
            store:                        'Loja',
            store_atividades:             'Atividades',
            store_tarifas:                'Tarifas',
            store_tarifas_info:           '* Vendas exclusivas Online. Não se efetuam vendas na Estância de Ski.',
            store_equipamentos:           'Aluguer de Capacetes e Bastões',
            store_forfaits:               'PASSE PARA PRATICANTES - SEM Aluguer de Equipamento',
            store_aulas:                  'Aulas',
            store_packs:                  'Pack Aluguer de Equipamento + Passe',
            store_packs_txt:              'Inclui o acesso às pistas, meios mecânicos e seguro em caso de acidente. O passe é de aquisição obrigatória a qualquer praticante de ski ou snowboard. Inclui o aluguer de equipamento para a prática de ski ou snowboard. Não inclui roupa ou acessórios. Capacete incluído para todos os praticantes. Obrigatório para menores de 12 anos. Não inclui aula.',
            store_vouchers:               'Vouchers',
            store_cart:                   'Carrinho',
            payment_pendente:             'Pagamento pendente',
            payment_failed:               'A sua encomenda não foi registada.',
            payment_success:              'A sua encomenda foi registada com sucesso.',
            estado:                         'Estado',
            pagamento_efectuado:            'Pagamento efectuado',
            num_encomenda:                  'N.º da encomenda',
            confirmacao_pagamento:          'Confirmação de pagamento',
            dados_vat:                      'NIF',
            dados_pagamento:                'Dados de pagamento',
            pagamento_cartao:               'Pagamento através de Cartão Bancário.',
            pagamento_mbw:                  'Pagamento por MB Way para o número',
            data_limite:                    'Data limite de pagamento' ,
            data_limite_txt:                'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada. Depois do pagamento, receberá a fatura dentro de 72 horas.',
            entity:                         'Entidade',
            reference:                      'Referência',
            subtotal:                       'Subtotal', 
            total:                          'Total',
            chamada_rede_fixa:              'Chamada para a rede fixa nacional',
            chamada_rede_movel:             'Chamada para a rede móvel nacional',
            store_forfaits_txt:             'A compra neste setor é destinada a praticantes de ski ou snowboard com equipamento próprio. Se pretende alugar equipamento, efetue a compra em "Pack Equipamento + Passe". O passe é de aquisição obrigatória para qualquer praticante de ski/snowboard e permite o acesso às pistas, meios mecânicos e seguro em caso de acidente. Não inclui aluguer de equipamento ou aulas.',
            store_equipamentos_txt:         'Capacete incluído no equipamento completo para menores de 12 anos. ATENÇÃO! Não inclui passe, equipamento ou aula.',
            store_aulas_txt_0:              'ATENÇÃO! VERIFICAÇÃO DE DISPONIBILIDADE E MARCAÇÃO OBRIGATÓRIAS.',
            store_aulas_txt:                'A aula não inclui passe ou aluguer de equipamento. Cada aula tem a duração de 55 minutos e corresponde a uma modalidade.',
            comprar:                        'Comprar',
            alugar:                         'Alugar',


            finalize_order:             'Finalizar',  
            voltar:                     'Voltar',
            precos:                     'Preço',
            quantidade:                 'Quantidade',
            sem_produtos:               'Sem produtos disponíveis',

            loja_fechada:               'Temporariamente indisponível',
        },
    'en':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_app:         'Acesso à área de cliente',
            access_data:        'Access data',
            accesses:           'Accesses',
            accesses_info:      'Information under the responsibility of Infraestruturas de Portugal. <br><br>If this information is not up to date, consult the website: <a href="https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real" target="_blank">https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real</a>',
            adress:             'Address',
            advice:             'The contents used in the elaboration of this platform are merely illustrative, having been taken (mostly) from the website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> These contents are licensed by authors and publishers to be freely used and adapted.',
            agree_terms_conditions:   'I agree to ',
            article:            'Article',
            articles_submission:'Article Submission',
            at:                 'at',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Go back',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            cancel:             'Cancel',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:       'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:           'Password updated successfully',
            client_regist:      'Resgisto de Novo Cliente',
            close:              'Close',
            closed:             'Closed',
            code:               'code',
            complaint_book:     'Complaint Book',
            confirmation_password_error_message:      'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',  
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            content_preview:    'Isto é uma Pré-visualização!', 
            cookies_message:    '<strong>ATTENTION:</strong> This site uses cookies. By using this site, you agree to the use of cookies.',
            cookie_button_text : 'Accept',
            country:            'Country',
            current_state:      'Current State',
            dates:              'Dates',
            birthday:         'Data de nascimento',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Last update',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Developed by',
            dificil:            'Difficult',
            difficulty:         'Difficulty',
            discover:           'Discover',
            download_app:       'Download app',
            payment_methods:    'Payment Methods',
            download_full_edition:           'Download full edition',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Informamos que recebemos a tua candidatura, iremos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Insert the email',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            facil:              'Easy',
            faqs:               'Frequently Asked Questions', 
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Street Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   'Street Lorem ipsum dolor sit amet, consectetur adipiscing eli',
            footer_address_2:   '275 000 000',
            footer_address_3:   'email@email.com',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            galleries:          'Galleries',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            information:        'Information',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            labels:             'Subtitles',
            latest_update:      'Latest update',
            learn_more:         'Learn more',
            livecam:            'Livecam',
            livecam_2:          'Camera',
            legal_information:  'Legal information',
            length:             'Length',
            location:           'Location',
            login:              'Login',
            logout:             'Logout',
            maximum_numbers_of_caracters:   'Maximum number of characters',
            marked_tracks:          'Marked lanes',
            mechanical_means:       'Lifts',
            mechanical_means_info:  '137 meter ski slope | 1988m - 1851m',
            message:                'Message',
            message_placeholder:    'Insert the message',
            meteorology:            'Weather',
            more:                   'More info:',
            more_information:        'More information.',
            muito_dificil:          'Very difficult',
            muito_facil:            'Very easy',
            multimedia:             'Multimedia',
            name:                   'Name',
            name_placeholder:       'Insert the name',
            natural_tracks:         'Natural trails',
            natural_tracks_info:    'Open slopes whenever there is enough snow',
            not_applicable:   'Not applicable',
            not_found:        'Home',
            not_found1:       'Oops! Nothing was found',
            not_found2:       'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. ',
            not_found3:       'Return to homepage.',
            observations:     'Observations',
            opinion_banner_button:   'Leave opinion',
            opinion_banner_title:    'Leave us your opinion',
            opinion_banner_text:     'Your opinion also counts',
            organization:       'Organization',
            open:               'Open',
            open_file:          'Open file',
            opinion_form:       'Opinion form',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            partnerships:       'Partnerships',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            personal_data:      'Dados pessoais',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            programs:           'Programs',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            projeto_cofinanciado: 'EU co-financed project: CENTRO-04-38D7-FEDER-001180',
            px_hour:            'px/hour',
            read_article:       'Read article',
            read_terms_conditions: 'Please read our terms and conditions before submitting the form.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            regulations:        'General class conditions',
            remove:             'Remove',
            reserved_rights:    'All rights reserved',
            resort_serra_da_estrela:    'Ski Resort Serra da Estrela',
            return_policy:      'Return and Refund Policy',
            reviewers:          'Reviewers',  
            //rules:              'Rules',
            save:               'Salvar',  
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:              'Search by author name', 
            search_by_article_keyword:          'Search by keywords', 
            search_error_message:               'Fill in one of the search fields.',
            search_error_noresults_message:     'No results found.',
            seats:              'Seats',
            see_it_all:         'See it all',
            send:               'Send',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            site_map:           'Site map',
            ski_school:         'Ski School', 
            slide_title:        'Together we share knowledge',
            subject:            'Subject',
            snowpark:           'Snowpark',
            snowpark_info:      'In the snowpark it is advisable to wear a helmet',
            social_media:       'Social Media',
            start:              'Start',
            state:              'State',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            temperature:        'Temperature',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Terms and conditions',
            terms_conditions_link:   'terms and conditions.',
            terms_conditions_required: 'The fields marked with (*) are required. The email address allows for faster contact in the event of further clarification. The information you submit will not be used or distributed for any other reason other than the processing of your request.',
            testimonial_form:       'Testimonial form',
            testimonials:       'Testimonials',
            testimonials_button_message:       'Leave testimony',
            technical_team:               'Technical team',
            tooltip_bar:                  'Bar',
            tooltip_forfait_sales:        'Ski pass sales point',
            tooltip_material_rental:      'Material rent',
            tooltip_parking_lot:          'Parking lot',
            tooltip_restaurant:           'Restaurant',
            tooltip_shopping_center:      'Shopping center',
            tooltip_wc:                   'WC',
            track_map:                    'Track map',
            tracks:                       'Tracks',
            tracks_with_snow_cannon:      'Trails with snow cannons',
            turistrela_group:             'Turistrela Group',
            turistrela_eat:               'Where to eat',
            turistrela_sleep:             'Where to sleep',
            unevenness:                   'Unevenness',
            user:                         'User',
            username_email:               'Email para login', 
            username_error_message:       'There is already a record with this username.',
            velocity:                     'Speed',
            web_page:                      'Webpage',
            agendar_aula:                   'Book a Class',       
            my_account:                   'Personal data',
            store:                        'Store',
            store_atividades:             'Activities',
            store_tarifas:                'Tariffs',
            store_tarifas_info:           '* Exclusive Online Sales. No sales are made at the Ski Resort.',
            store_equipamentos:           'Helmet and Pole Rental',
            store_forfaits:               'Forfaits - NO Equipment Rental',
            store_aulas:                  'Classes',
            store_packs:                  'Pack Equipment Rental + Forfait',
            store_packs_txt:              'Includes access to the tracks, mechanical means and insurance in case of an accident. The forfait is mandatory for any ski or snowboarder. Includes rental of equipment for skiing or snowboarding. Does not include clothing or accessories. Helmet included up to 12 years old. Does not include class.',
            store_vouchers:               'Vouchers',
            store_cart:                   'Cart',
            payment_pendente:             'Pagamento pendente',
            payment_failed:               'A sua encomenda não foi registada.',
            payment_success:              'A sua encomenda foi registada com sucesso.',
            estado:                         'Estado',
            pagamento_efectuado:            'Pagamento efectuado',
            num_encomenda:                  'N.º da encomenda',
            confirmacao_pagamento:          'Confirmação de pagamento',
            dados_vat:                      'NIF',
            dados_pagamento:                'Dados de pagamento',
            pagamento_cartao:               'Pagamento através de Cartão Bancário.',
            pagamento_mbw:                  'Pagamento por MB Way para o número',
            data_limite:                    'Data limite de pagamento' ,
            data_limite_txt:                'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada. Depois do pagamento, receberá a fatura dentro de 72 horas.',
            entity:                         'Entidade',
            reference:                      'Referência',
            subtotal:                       'Subtotal', 
            total:                          'Total',
            chamada_rede_fixa:              'Chamada para a rede fixa nacional',
            chamada_rede_movel:             'Chamada para a rede móvel nacional',
            store_forfaits_txt:             'Purchases in this sector are intended for practitioners with equipment own. If you intend to rent equipment, make the purchase in "Pack Equipment + Forfait." The pass is mandatory for any ski/snowboarder and allows access to the slopes, mechanical means and insurance in case of accident. Does not include rental equipment or classes.',
            store_equipamentos_txt:         'Helmet included in complete equipment for children under 12 years old. ATTENTION! Does not include pass, equipment or class.',
            store_aulas_txt_0:              'ATTENTION! AVAILABILITY CHECK AND MANDATORY BOOKING.',
            store_aulas_txt:                'The class does not include a pass or equipment rental. Each class lasts 55 minutes and corresponds to one modality.',
            comprar:                        'To buy',
            alugar:                         'To rent',


            finalize_order:             'Finalize',  
            voltar:                     'Back',
            precos:                     'Price',
            quantidade:                 'Quantity',
            sem_produtos:               'No products available',

            loja_fechada:               'Temporarily unavailable',
        },
    'es':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_app:         'Acesso à área de cliente',
            access_data:        'Access data',
            accesses:           'Accesos',
            accesses_info:      'Información de la responsabilidad de Infraestruturas de Portugal. <br><br>Si esta información no está actualizada, consulte el sitio web: <a href="https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real" target="_blank">https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real</a>',
            adress:             'Address',
            advice:             'The contents used in the elaboration of this platform are merely illustrative, having been taken (mostly) from the website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> These contents are licensed by authors and publishers to be freely used and adapted.',
            agree_terms_conditions:   'Estoy de acuerdo con ',
            article:            'Article',
            articles_submission:'Article Submission',
            at:                 'a las',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Volver',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            cancel:             'Cancel',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:    'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:    'Password updated successfully',
            client_regist:      'Resgisto de Novo Cliente',
            close:              'Close',
            closed:             'Cerrado',
            code:               'code',
            complaint_book:     'Libro de reclamaciones',
            confirmation_password_error_message:               'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',  
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            content_preview:    'Isto é uma Pré-visualização!', 
            cookies_message:    '<strong>ATENCIÓN:</strong> Este sitio utiliza cookies. Al utilizar este sitio, usted acepta el uso de cookies.',
            cookie_button_text : 'Aceptar',
            country:            'Country',
            current_state:      'Estado Actual',
            dates:              'Dates',
            birthday:         'Data de nascimento',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Last update',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Desarrollado por',
            dificil:            'Difícil',
            difficulty:         'Dificultad',
            discover:           'Discover',
            download_app:       'Descargar aplicación',
            payment_methods:    'Formas de pago',
            download_full_edition:           'Download full edition',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Informamos que recebemos a tua candidatura, iremos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Insert the email',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            facil:              'Fácil',
            faqs:               'Preguntas más frecuentes',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Street Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   'Street Lorem ipsum dolor sit amet, consectetur adipiscing eli',
            footer_address_2:   '275 000 000',
            footer_address_3:   'email@email.com',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            galleries:          'Galerias',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            information:        'Información',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            labels:             'Subtítulos',
            latest_update:      'Última actualización',
            learn_more:         'Saber mas',
            legal_information:         'Información legal',
            length:             'Longitud',
            livecam:            'Livecam',
            livecam_2:           'Cámara',
            location:           'Ubicación',
            login:              'Login',
            logout:             'Salir',
            marked_tracks:              'Pistas marcadas',
            maximum_numbers_of_caracters:   'Número máximo de caracteres',
            mechanical_means:   'Medios mecánicos',
            mechanical_means_info:   'Pista de esquí de 137 metros | 1988m - 1851m',
            message:            'Mensaje',
            message_placeholder:'Insert the message',
            meteorology:        'Meteorología',
            more:               'More info:',
            more_information:        'Más información.',
            muito_dificil:      'Muy difícil',
            muito_facil:        'Muy fácil',
            multimedia:         'Multimedia',
            name:               'Nombre',
            name_placeholder:   'Insert the name',
            natural_tracks:     'Pistas naturales',
            natural_tracks_info:     'Pistas abiertas siempre que haya nieve suficiente',
            not_applicable:       'No aplicable',
            not_found:          'Home',
            not_found1:          'Oops! Nothing was found',
            not_found2:          'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. ',
            not_found3:          'Return to homepage.',
            observations:       'Observaciones',
            opinion_banner_button:   'Leave opinion',
            opinion_banner_title:   'Leave us your opinion',
            opinion_banner_text:    'Your opinion also counts',
            organization:       'Organization',
            open:               'Abierto',
            open_file:          'Open file',
            opinion_form:       'Opinion form',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            partnerships:       'Asociaciones',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            personal_data:      'Dados pessoais',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            programs:           'Programas',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            projeto_cofinanciado: 'Proyecto cofinanciado por la UE: CENTRO-04-38D7-FEDER-001180',
            px_hour:            'px/hora',
            read_article:       'Read article',
            read_terms_conditions: 'Lea nuestras condiciones generales antes de enviar el formulario.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            regulations:        'Condiciones generales de la clase',
            remove:             'Remove',
            reserved_rights:    'All rights reserved',
            resort_serra_da_estrela:    'Ski Resort Serra da Estrela',
            return_policy:      'Política de Devolución y Reembolso',
            reviewers:          'Reviewers',  
            //rules:              'Rules',
            save:               'Salvar',  
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:             'Search by author name', 
            search_by_article_keyword:             'Search by keywords', 
            search_error_message:             'Fill in one of the search fields.',
            search_error_noresults_message:             'No results found.',
            seats:              'Plazas',
            see_it_all:         'See it all',
            send:               'Mandar',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            site_map:           'Mapa del sitio',
            ski_school:         'Escola de Ski', 
            slide_title:        'Together we share knowledge',
            subject:            'Sujeto',
            snowpark:           'Parque de nieve',
            snowpark_info:      'Se recomienda el uso del casco en el parque de nieve',
            social_media:       'Redes Sociales',
            start:              'Start',
            state:              'Estado',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            temperature:        'Temperatura',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Términos y condiciones',
            terms_conditions_link:   'términos y condiciones.',
            terms_conditions_required: 'Los campos marcados con (*) son obligatorios. La dirección de correo electrónico permite un contacto más rápido en caso de más aclaraciones. La información que envíe no será utilizada ni distribuida por ningún otro motivo que no sea el procesamiento de su solicitud.',
            testimonial_form:       'Formulario de testimonio',
            testimonials:       'Testimonios',
            testimonials_button_message:       'Dejar testimonio',
            technical_team:     'Technical team',
            tooltip_bar:        'Bar',
            tooltip_forfait_sales: 'Pases a la venta',
            tooltip_material_rental: 'Alquiler de materiales',
            tooltip_parking_lot: 'Estacionamiento',
            tooltip_restaurant:     'Restaurante',
            tooltip_shopping_center: 'Centro comercial',
            tooltip_wc:         'WC',
            track_map:          'Plano de las pistas',
            tracks:             'Pistas',
            tracks_with_snow_cannon:    'Senderos con cañones de nieve',
            turistrela_group:   'Grupo Turistrela',
            turistrela_eat:     'Dónde comer',
            turistrela_sleep:   'Dónde dormir',
            unevenness:         'Desnivel',
            user:               'User',
            username_email:               'Email para login', 
            username_error_message:  'There is already a record with this username.',
            velocity:           'velocidad',
            web_page:           'Web page',

            agendar_aula:       'Agendar Aula',
            my_account:                   'Mis datos',
            store:                        'Tienda',
            store_atividades:             'Actividades',
            store_tarifas:                'Tarifas',
            store_tarifas_info:           '* Ventas exclusivas en línea. No se realizan ventas en la Estación de Esquí.',
            store_equipamentos:           'Alquiler de cascos y bastones',
            store_forfaits:               'Forfaits - NO alquiler de equipos',
            store_aulas:                  'Clases',
            store_packs:                  'Pack Alquiler de Equipo + Forfait',
            store_packs_txt:              'Incluye acceso a las pistas, medios mecánicos y seguro en caso de accidente. El forfait es obligatorio para cualquier practicante de esquí o snowboard. Incluye alquiler de material para esquí o snowboard. No incluye ropa ni accesorios. Casco incluido hasta los 12 años. No incluye clase.',
            store_vouchers:               'Vouchers',
            store_cart:                   'Carrito',
            payment_pendente:             'Pagamento pendente',
            payment_failed:               'A sua encomenda não foi registada.',
            payment_success:              'A sua encomenda foi registada com sucesso.',
            estado:                         'Estado',
            pagamento_efectuado:            'Pagamento efectuado',
            num_encomenda:                  'N.º da encomenda',
            confirmacao_pagamento:          'Confirmação de pagamento',
            dados_vat:                      'NIF',
            dados_pagamento:                'Dados de pagamento',
            pagamento_cartao:               'Pagamento através de Cartão Bancário.',
            pagamento_mbw:                  'Pagamento por MB Way para o número',
            data_limite:                    'Data limite de pagamento' ,
            data_limite_txt:                'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada. Depois do pagamento, receberá a fatura dentro de 72 horas.',
            entity:                         'Entidade',
            reference:                      'Referência',
            subtotal:                       'Subtotal', 
            total:                          'Total',
            chamada_rede_fixa:              'Chamada para a rede fixa nacional',
            chamada_rede_movel:             'Chamada para a rede móvel nacional',
            store_forfaits_txt:             'Las compras en este sector están destinadas a profesionales con equipamiento. propio. Si pretendes alquilar equipo, realiza la compra en "Pack Equipo + Forfait." El pase es obligatorio para cualquier practicante de esquí/snowboard y permite el acceso a las pistas, medios mecánicos y seguro en caso de accidente. No incluye alquiler equipos o clases.',
            store_equipamentos_txt:         'Casco incluido en el equipamiento completo para niños menores de 12 años. ¡ATENCIÓN! No incluye pase, equipo ni clase.',
            store_aulas_txt_0:              '¡ATENCIÓN! CONSULTA DISPONIBILIDAD Y RESERVA OBLIGATORIA.',
            store_aulas_txt:                'La clase no incluye pase ni alquiler de equipo. Cada clase tiene una duración de 55 minutos y corresponde a una modalidad.',            
            comprar:                        'Comprar',
            alugar:                         'Alquilar',


            finalize_order:             'Finalizar',  
            voltar:                     'Volver',
            precos:                     'Precio',
            quantidade:                 'Cantidad',
            sem_produtos:               'No hay productos disponibles',

            loja_fechada:               'No disponible temporalmente',
        },
    'fr':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_app:         'Acesso à área de cliente',
            access_data:        'Access data',
            accesses:           'Accès',
            accesses_info:      'Informations sous la responsabilité d\'Infraestruturas de Portugal. <br><br>Si ces informations ne sont pas à jour, consulter le site internet: <a href="https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real" target="_blank">https://servicos.infraestruturasdeportugal.pt/viajar-na-estrada/transito-em-tempo-real</a>',
            adress:             'Address',
            advice:             'The contents used in the elaboration of this platform are merely illustrative, having been taken (mostly) from the website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> These contents are licensed by authors and publishers to be freely used and adapted.',
            agree_terms_conditions:   'Je suis d\'accord avec ',
            article:            'Article',
            articles_submission:'Article Submission',
            at:                 'at',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_02:  'Desporto',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Agroalimentar',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Fase de maturidade do negócio',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Histórico do(s) promotor(es)',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Previsões',
            back:               'Retourner',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            cancel:             'Cancel',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:       'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:           'Password updated successfully',
            client_regist:      'Resgisto de Novo Cliente',
            close:              'Close',
            closed:             'Fermé',
            code:               'code',
            complaint_book:     'Cahier de doléances',
            confirmation_password_error_message:      'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',  
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            content_preview:    'Isto é uma Pré-visualização!', 
            cookies_message:    '<strong>ATTENTION :</strong> Ce site utilise des cookies. En utilisant ce site, vous acceptez l’utilisation de cookies.',
            cookie_button_text : 'Accepter',
            country:            'Country',
            current_state:      'État actuel',
            dates:              'Dates',
            birthday:         'Data de nascimento',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Dernière changement',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Développé par',
            dificil:            'Difficile',
            difficulty:         'Difficulté',
            discover:           'Discover',
            download_app:       'Download app',
            payment_methods:    'Les modes de paiement',
            download_full_edition:           'Download full edition',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Informamos que recebemos a tua candidatura, iremos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_placeholder:  'Insert the email',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            facil:              'Facile',
            faqs:               'Questions fréquemment posées',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Street Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   'Street Lorem ipsum dolor sit amet, consectetur adipiscing eli',
            footer_address_2:   '275 000 000',
            footer_address_3:   'email@email.com',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            galleries:          'Galeries',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            information:        'Information',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            labels:             'Sous-titres',
            latest_update:      'Dernière changement',
            learn_more:         'En savoir plus',
            livecam:            'Livecam',
            livecam_2:          'Caméra',
            legal_information:  'Information légale',
            length:             'Longueur',
            location:           'Emplacement',
            login:              'Login',
            logout:             'Sortir',
            maximum_numbers_of_caracters:   'Nombre maximum de caractères',
            marked_tracks:          'Pistes marquées',
            mechanical_means:       'Moyens mécanique',
            mechanical_means_info:  'Piste de ski de 137 mètres - 1988m - 1851m',
            message:                'Message',
            message_placeholder:    'Insert the message',
            meteorology:            'Météorologie',
            more:                   'More info:',
            more_information:        'Plus d\'information.',
            muito_dificil:          'Très difficile',
            muito_facil:            'Très facile',
            multimedia:             'Multimedia',
            name:                   'Nom',
            name_placeholder:       'Insert the name',
            natural_tracks:         'Pistes naturelles',
            natural_tracks_info:    'Pistes ouvertes dès qu\'il y a suffisamment de neige',
            not_applicable:   'Non applicable',
            not_found:        'Home',
            not_found1:       'Oops! Nothing was found',
            not_found2:       'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. ',
            not_found3:       'Return to homepage.',
            observations:     'Observations',
            opinion_banner_button:   'Leave opinion',
            opinion_banner_title:    'Leave us your opinion',
            opinion_banner_text:     'Your opinion also counts',
            organization:       'Organization',
            open:               'Ouvert',
            open_file:          'Open file',
            opinion_form:       'Formulaire d\'opinion',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            partnerships:       'Partenariats',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            personal_data:      'Dados pessoais',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            programs:           'Programs',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            projeto_cofinanciado: 'Projet cofinancé par l\'UE: CENTRO-04-38D7-FEDER-001180',
            px_hour:            'px/hour',
            read_article:       'Read article',
            read_terms_conditions: 'Veuillez lire nos conditions générales avant de soumettre le formulaire.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            regulations:        'Conditions générales de la classe',
            remove:             'Remove',
            reserved_rights:    'All rights reserved',
            resort_serra_da_estrela:    'Ski Resort Serra da Estrela',
            return_policy:      'Politique de Retour et de Remboursement',
            reviewers:          'Reviewers',  
            //rules:              'Rules',
            save:               'Salvar',  
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:              'Search by author name', 
            search_by_article_keyword:          'Search by keywords', 
            search_error_message:               'Fill in one of the search fields.',
            search_error_noresults_message:     'No results found.',
            seats:              'Sièges',
            see_it_all:         'See it all',
            send:               'Envoyer',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            site_map:           'Plan du site',
            ski_school:         'École de Ski', 
            slide_title:        'Together we share knowledge',
            subject:            'Sujet',
            snowpark:           'Snowpark',
            snowpark_info:      'Le port du casque est recommandé dans le snowpark.',
            social_media:       'Réseaux sociaux',
            start:              'Start',
            state:              'State',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            temperature:        'Température',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Conditions générales',
            terms_conditions_link:   'conditions générales.',
            terms_conditions_required: 'Les champs marqués d\'un (*) sont obligatoires. L\'adresse email permet un contact plus rapide en cas de précisions complémentaires. Les informations que vous soumettez ne seront ni utilisées ni distribuées pour toute autre raison que le traitement de votre demande.',
            testimonial_form:               'Témoignages form',
            testimonials:                   'Témoignages',
            testimonials_button_message:       'Laisser un témoignage',
            technical_team:               'Technical team',
            tooltip_bar:                  'Bar',
            tooltip_forfait_sales:        'Ski pass sales point',
            tooltip_material_rental:      'Material rent',
            tooltip_parking_lot:          'Parking lot',
            tooltip_restaurant:           'Restaurant',
            tooltip_shopping_center:      'Shopping center',
            tooltip_wc:                   'WC',
            track_map:                    'Plan des pistes',
            tracks:                       'Pistes',
            tracks_with_snow_cannon:      'Pistes avec canons à neige',
            turistrela_group:             'Turistrela Groupe',
            turistrela_eat:               'Où manger',
            turistrela_sleep:             'Où dormir',
            unevenness:                   'Dénivelé',
            user:                         'User',
            username_email:               'Email para login', 
            username_error_message:       'There is already a record with this username.',
            velocity:                     'Vitesse',
            web_page:                     'Webpage',
            agendar_aula:                 'Book a Class',       
            my_account:                   'Mes données',
            store:                        'Magasin',
            store_atividades:             'Activités',
            store_tarifas:                'Tarifs',
            store_tarifas_info:           '* Ventes en ligne exclusives. Aucune vente n\'est effectuée à la Station de Ski.',
            store_equipamentos:           'Location de casques et bâtons',
            store_forfaits:               "Forfaits - AUCUNE location d'équipement",
            store_aulas:                  'Des classes',
            store_packs:                  'Packs',
            store_packs:                  'Pack Location de matériel + Forfait',
            store_packs_txt:              "Comprend l'accès aux pistes, les moyens mécaniques et l'assurance en cas d'accident. Le forfait est obligatoire pour tout skieur ou snowboardeur. Comprend la location de matériel de ski ou de snowboard. N'inclut pas les vêtements ni les accessoires. Casque inclus jusqu'à 12 ans. N'inclut pas les cours.",
            store_vouchers:               'Vouchers',
            store_cart:                   'Panier',
            payment_pendente:             'Pagamento pendente',
            payment_failed:               'A sua encomenda não foi registada.',
            payment_success:              'A sua encomenda foi registada com sucesso.',
            estado:                         'État',
            pagamento_efectuado:            'Pagamento efectuado',
            num_encomenda:                  'N.º da encomenda',
            confirmacao_pagamento:          'Confirmação de pagamento',
            dados_vat:                      'NIF',
            dados_pagamento:                'Dados de pagamento',
            pagamento_cartao:               'Pagamento através de Cartão Bancário.',
            pagamento_mbw:                  'Pagamento por MB Way para o número',
            data_limite:                    'Data limite de pagamento' ,
            data_limite_txt:                'Caso não efectue o pagamento dentro da data limite, a sua encomenda será anulada. Depois do pagamento, receberá a fatura dentro de 72 horas.',
            entity:                         'Entidade',
            reference:                      'Referência',
            subtotal:                       'Subtotal', 
            total:                          'Total',
            chamada_rede_fixa:              'Chamada para a rede fixa nacional',
            chamada_rede_movel:             'Chamada para a rede móvel nacional',
            store_forfaits_txt:             "Les achats dans ce secteur sont destinés aux praticiens disposant de matériel propre. Si vous comptez louer du matériel, effectuez l’achat en \"Pack Équipement + Forfait\". Le pass est obligatoire pour tout skieur/snowboardeur et permet l'accès aux pistes, moyens mécaniques et assurance en cas d'accident. N'inclut pas la location équipement ou cours.",
            store_equipamentos_txt:         "Casque inclus dans l'équipement complet pour les enfants de moins de 12 ans. ATTENTION! N'inclut pas le pass, l'équipement ou le cours.",
            store_aulas_txt_0:              "ATTENTION! VÉRIFICATION DES DISPONIBILITÉS ET RÉSERVATION OBLIGATOIRE.",
            store_aulas_txt:                "Le cours n'inclut pas de pass ni de location de matériel. Chaque cours dure 55 minutes et correspond à une modalité.",
            comprar:                        'Acheter',
            alugar:                         'Location',


            finalize_order:             'Finalizer',  
            voltar:                     'Retourner',
            precos:                     'Prix',
            quantidade:                 'Quantité',
            sem_produtos:               'Aucun produit disponible',

            loja_fechada:               'Temporairement indisponible',
        },
}

module.exports = {
    language
};